import * as Klaro from 'klaro/dist/klaro-no-css';

const config = {
    version: 1,
    elementID: 'klaro',
    styling: {
        theme: [
            'light',
            // 'top',
            // 'wide'
        ],
    },
    htmlTexts: true,
    embedded: false,
    groupByPurpose: false,
    storageMethod: 'cookie',
    cookieName: 'klaro',
    cookieExpiresAfterDays: 365,
    //cookieDomain: '.github.com',
    //cookiePath: '/',
    default: false,
    mustConsent: false,
    acceptAll: true,
    hideDeclineAll: false,
    hideLearnMore: false,
    noticeAsModal: false,
    //disablePoweredBy: true,
    //additionalClass: 'my-klaro',
    //lang: 'de',
    translations: {
        // zz = default translations.
        zz: {},
        de: {
            privacyPolicyUrl: '/datenschutz',
            consentNotice: {
                changeDescription:
                    'Es gab Änderungen seit Ihrem letzten Besuch, bitte aktualisieren Sie Ihre Auswahl.',
                description:
                    'Sie können dem Einsatz von externen Services (Push-Benachrichtigungen, Live-Chat, Google Maps, YouTube, Vimeo) hier zustimmen, ihn ablehnen oder individuelle Einstellungen treffen.',
                learnMore: 'Individuelle Einstellungen',
            },
            consentModal: {
                title: 'Ihre Privatsphäre-Einstellungen',
                description:
                    'Hier können Sie einsehen und anpassen, welche persönlichen Daten wir erfassen und welche externen Services wir nutzen.',
                privacyPolicy: {
                    name: 'Datenschutzerklärung',
                    text: 'Weitere Details finden Sie in unserer {privacyPolicy}.',
                },
            },
            ok: 'Zustimmen',
            save: 'Speichern',
            decline: 'Ablehnen',
            close: 'Schließen',
            acceptSelected: 'Auswahl speichern',
            acceptAll: 'Allen zustimmen',
            app: {
                disableAll: {
                    title: 'Alle Anwendungen umschalten',
                    description:
                        'Mit dieser Schaltfläche können Sie alle Anwendungen auf einmal aktivieren bzw. deaktivieren.',
                },
                optOut: {
                    title: '(Opt-Out)',
                    description:
                        'Diese Anwendung wird standardmäßig geladen (Sie können sie aber deaktivieren)',
                },
                required: {
                    title: '(immer notwendig)',
                    description: 'Diese Anwendung wird immer benötigt.',
                },
                purposes: 'Verwendungszwecke',
                purpose: 'Verwendungszweck',
            },
            poweredBy: 'Umgesetzt mit Klaro!',

            purposes: {
                general: 'Basisfunktionalität & Sicherheit',
                multimedia: 'Externe Inhalte',
                communication: 'Kommunikation & Benachrichtigungen',
                analytics: 'Statistik',
            },

            // translations for service descriptions
            klaro: {
                description:
                    'In dem Klaro-Cookie werden Ihre hier getroffenen Privatsphäre-Einstellungen gespeichert.',
            },
            csrf: {
                description: 'Verhindert gefälschte Anfragen von externen Seiten.',
            },
            Matomo: {
                description:
                    'Wir verwenden die selbst-gehostete Open-Source-Software Matomo, um Besucherstatistiken zu erstellen. Hierbei werden Ihre Daten pseudonymisiert verarbeitet. Ihre Daten aus unterschiedlichen Sitzungen werden nicht zusammengeführt, wir erstellen kein Profil von Ihrem Nutzerverhalten und wir verwenden keine Cookies in Matomo.',
            },
            OneSignal: {
                description:
                    'Wir verwenden OneSignal, um Ihnen browserbasierte Push-Benachrichtigungen anzubieten. Nach der Aktivierung dieses Services können Sie Push-Benachrichtigungen über Ihren Browser abonnieren.',
            },
            Brevo: {
                description:
                    'Wir verwenden Brevo (ehemals Sendinblue), um Ihnen einen Live-Chat zur Verfügung zu stellen. Über den Live-Chat können Sie Fragen und Vorschläge direkt an die Fachredaktion richten. Brevo verwendet cloudfront.net, um den Dienst ausfallsicher anbieten zu können.',
            },
            GoogleMaps: {
                description:
                    'Diese Option aktiviert eingebettete Karten über Google Maps. Für die Einbettung setzen wir den Dienst unpkg.com ein.',
            },
            YouTube: {
                description:
                    'Diese Option aktiviert eingebettete YouTube-Videos und YouTube-Livestreams.',
            },
            Vimeo: {
                description: 'Diese Option aktiviert eingebettete Vimeo-Videos.',
            },
            Podigee: {
                description: 'Diese Option aktiviert eingebettete Podcasts über Podigee.',
            },
        },
    },

    // This is a list of third-party services that Klaro will manage for you.
    services: [
        {
            name: 'klaro',
            default: true,
            required: true,
            title: 'Privatsphäre-Einstellungen',
            purposes: ['general'],
            cookies: ['klaro'],
        },
        {
            name: 'csrf',
            default: true,
            required: true,
            title: 'CSRF Schutz',
            purposes: ['general'],
            cookies: ['CRAFT_CSRF_TOKEN', 'CraftSessionId'],
        },
        {
            name: 'Matomo',
            default: true,
            required: true,
            title: 'Pseudonymisierte Benutzerstatistik (Matomo)',
            purposes: ['analytics'],
            cookies: [/^_pk_.*$/],
        },
        {
            name: 'OneSignal',
            default: false,
            onlyOnce: true,
            title: 'Push-Benachrichtigungen (OneSignal)',
            purposes: ['communication'],
            cookies: [
                '__cfduid',
                /^ONE_SIGNAL.*$/,
                /^ONESIGNAL.*$/,
                /^onesignal.*$/,
                'os_pageViews',
                'isOptedOut',
                'isPushNotificationsEnabled',
            ],
        },
        {
            name: 'Brevo',
            default: false,
            onlyOnce: true,
            title: 'Brevo Chat',
            purposes: ['communication'],
            cookies: [/^sib_.*$/],
        },
        {
            name: 'GoogleMaps',
            title: 'Google Maps',
            purposes: ['multimedia'],
            callback(consent, service) {
                if (!consent) return;
                // wait until all scripts the map depends on are loaded before initializing the map
                const allDependentScripts = Array.from(
                    document.querySelectorAll('script[data-name="GoogleMaps"]'),
                );
                const awaitScriptsLoaded = allDependentScripts.map(
                    script =>
                        new Promise(resolve => {
                            script.onload = () => resolve(script);
                        }),
                );
                Promise.all(awaitScriptsLoaded).then(() => {
                    // initialize the map on offer/institute pages, if any
                    const offerMap = document.getElementById('offer-institute-single-map');
                    if (offerMap) {
                        window.googleMaps.init('offer-institute-single-map', null);
                    }
                    // dispatch an event to allow other maps to initialize once
                    // all dependent scripts are ready
                    const googleMapsReadyEvent = new Event('google-maps-ready');
                    window.dispatchEvent(googleMapsReadyEvent);
                });
            },
        },
        {
            name: 'YouTube',
            title: 'YouTube',
            purposes: ['multimedia'],
        },
        {
            name: 'Vimeo',
            title: 'Vimeo',
            purposes: ['multimedia'],
        },
        {
            name: 'Podigee',
            title: 'Podigee',
            purposes: ['multimedia'],
        },
    ],
};

// Provide global access to the klaro instance and it's config.
window.klaro = Klaro;
window.klaroConfig = config;

// Initialize klaro with the given config.
Klaro.setup(config);

// Provide a utility class for links to open the klaro modal.
const managePrivacyPreferencesLinks = Array.from(document.querySelectorAll('.klaro-show'));
managePrivacyPreferencesLinks.forEach(link =>
    link.addEventListener('click', e => {
        e.preventDefault();
        window.klaro.show();
    }),
);
